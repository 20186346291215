"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formRule = exports.formOption = void 0;
var formOption = {
  form: {
    showMessage: true,
    inlineMessage: false,
    statusIcon: true,
    validateOnRuleChange: true,
    labelWidth: '100px',
    size: 'small'
  },
  row: {
    gutter: 10
  },
  submitBtn: false,
  resetBtn: false
};
exports.formOption = formOption;
var formRule = [{
  type: 'select',
  field: 'pos_limit',
  title: '校区',
  options: [],
  value: [],
  props: {
    multiple: true
  },
  validate: [{
    required: true,
    message: '请选择校区'
  }]
}, {
  type: 'select',
  field: 'role_id',
  title: '角色',
  options: [],
  props: {
    multiple: true
  }
}, {
  type: 'select',
  field: 'group_id',
  title: '部门',
  options: []
}];
exports.formRule = formRule;