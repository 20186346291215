var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { staticClass: "handle-container", attrs: { align: "bottom" } },
        [
          _c(
            "div",
            { staticClass: "filter-container" },
            [_c("page-filter", { on: { change: _vm.handleFilter } })],
            1
          ),
          _c(
            "div",
            { staticClass: "margin-bottom" },
            [
              _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  attrs: { type: "warning", size: "small" },
                  on: { click: _vm.handleFilterClear },
                },
                [_vm._v("刷新")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "auth",
                      rawName: "v-auth:firm_user_create",
                      arg: "firm_user_create",
                    },
                    { name: "waves", rawName: "v-waves" },
                  ],
                  attrs: { type: "success" },
                  on: { click: _vm.handleCreate },
                },
                [_vm._v("添加")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "auth",
                      rawName: "v-auth:firm_user_removelist",
                      arg: "firm_user_removelist",
                    },
                    { name: "waves", rawName: "v-waves" },
                  ],
                  attrs: {
                    loading: _vm.btnLoading,
                    disabled: _vm.btnDisabled,
                    type: "danger",
                  },
                  on: { click: _vm.handleRemoveList },
                },
                [_vm._v("批量删除")]
              ),
              _c(
                "el-dropdown",
                {
                  directives: [
                    {
                      name: "auth",
                      rawName: "v-auth:firm_user_updatelist",
                      arg: "firm_user_updatelist",
                    },
                  ],
                  staticStyle: { "margin-left": "10px" },
                  attrs: { trigger: "click", placement: "bottom" },
                  on: { command: _vm.handleCommand },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { disabled: _vm.btnDisabled, type: "Info" } },
                    [_vm._v("批量更新")]
                  ),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("el-dropdown-item", { attrs: { command: 1 } }, [
                        _vm._v("设为正常"),
                      ]),
                      _c("el-dropdown-item", { attrs: { command: 0 } }, [
                        _vm._v("设为禁用"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading",
            },
          ],
          key: _vm.tableKey,
          attrs: {
            border: "",
            fit: "",
            size: "mini",
            "highlight-current-row": "",
            data: _vm.tableData.data,
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "40" } }),
          _c("el-table-column", {
            attrs: { label: "ID", align: "center", width: "60px", fixed: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "头像", width: "65px", align: "center", fixed: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.avatar
                      ? _c(
                          "span",
                          {
                            staticClass: "link-type",
                            on: {
                              click: function ($event) {
                                return _vm.handleImg(scope.row.avatar)
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: scope.row.avatar,
                                width: "40",
                                height: "40",
                              },
                            }),
                          ]
                        )
                      : _c("i", {
                          staticClass: "el-icon-user",
                          staticStyle: { "font-size": "40px" },
                        }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "登录账户",
              width: "100px",
              align: "center",
              fixed: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: { path: "/core/user/info?id=" + scope.row.id },
                        },
                      },
                      [
                        _c("span", { staticClass: "link-type" }, [
                          _vm._v(_vm._s(scope.row.account || "未设置")),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "姓名", width: "110px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "手机", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.mobile))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "部门", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.group_title))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "角色", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.role_title))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "校区", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.pos_title))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "状态", width: "70px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-switch", {
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      on: {
                        change: function ($event) {
                          return _vm.handleUpdateStatus(scope.$index)
                        },
                      },
                      model: {
                        value: scope.row.status,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "status", $$v)
                        },
                        expression: "scope.row.status",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "登录IP", width: "120px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.login_ip))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "登录时间", width: "150px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.login_time))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "注册时间", width: "150px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.create_time))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "150px",
              align: "center",
              "class-name": "small-padding",
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          { name: "waves", rawName: "v-waves" },
                          {
                            name: "auth",
                            rawName: "v-auth:firm_user_update",
                            arg: "firm_user_update",
                          },
                        ],
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleUpdate(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          { name: "waves", rawName: "v-waves" },
                          {
                            name: "auth",
                            rawName: "v-auth:firm_user_remove",
                            arg: "firm_user_remove",
                          },
                        ],
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleRemove(scope.$index, scope.row.id)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination-container" },
        [
          _c("el-pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tableData.total > 0,
                expression: "tableData.total>0",
              },
            ],
            attrs: {
              "current-page": _vm.tableFilter.page,
              "page-sizes": [15, 20, 30, 50],
              "page-size": _vm.tableFilter.per_page,
              total: _vm.tableData.total,
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "可通过以下方式邀请员工加入",
            visible: _vm.visible,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _vm.inviteInfo.qrcode_url
            ? _c("div", [
                _c("div", { staticClass: "text-bold text-xl" }, [
                  _vm._v("小程序"),
                ]),
                _c("div", { staticClass: "padding" }, [
                  _c("img", {
                    staticStyle: { width: "160px", height: "160px" },
                    attrs: { src: _vm.inviteInfo.qrcode_url, alt: "" },
                  }),
                  _c("div", { staticClass: "padding-tb" }, [
                    _vm._v("微信扫码注册账号加入企业"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "btn margin-top-xs",
                      on: {
                        click: function ($event) {
                          return _vm.handleDown(_vm.inviteInfo.qrcode_url)
                        },
                      },
                    },
                    [_vm._v("点击下载二维码")]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.inviteInfo.link_url
            ? _c("div", [
                _c("div", { staticClass: "text-bold text-xl" }, [
                  _vm._v("邀请链接"),
                ]),
                _c("div", { staticClass: "padding-left" }, [
                  _c("div", { staticClass: "padding-tb" }, [
                    _vm._v("电脑端通过邀请链接注册账号加入企业"),
                  ]),
                  _c("div", { staticClass: "padding-bottom" }, [
                    _c("span", [_vm._v(_vm._s(_vm.inviteInfo.link_url))]),
                    _c(
                      "span",
                      {
                        staticClass: "btn margin-left",
                        on: {
                          click: function ($event) {
                            return _vm.handleCopy(_vm.inviteInfo.link_url)
                          },
                        },
                      },
                      [_vm._v("点击复制")]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.inviteInfo.inviter_id
            ? _c("div", [
                _c("div", { staticClass: "text-bold text-xl" }, [
                  _vm._v("邀请码"),
                ]),
                _c("div", { staticClass: "padding-left" }, [
                  _c("div", { staticClass: "padding-tb" }, [
                    _vm._v("电脑端通过邀请码 在右上角切换企业并加入企业"),
                  ]),
                  _c("div", [
                    _c("span", [_vm._v(_vm._s(_vm.inviteInfo.inviter_id))]),
                    _c(
                      "span",
                      {
                        staticClass: "btn margin-left",
                        on: {
                          click: function ($event) {
                            return _vm.handleCopy(_vm.inviteInfo.inviter_id)
                          },
                        },
                      },
                      [_vm._v("点击复制")]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _c("edit-form", {
        ref: "editForm",
        on: { updateRow: _vm.handleUpdateRow },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }