"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formRule = exports.formOption = void 0;
var formOption = {
  form: {
    showMessage: true,
    inlineMessage: false,
    statusIcon: true,
    validateOnRuleChange: true,
    labelWidth: '100px',
    size: 'small'
  },
  row: {
    gutter: 10
  },
  submitBtn: false,
  resetBtn: false
};
exports.formOption = formOption;
var formRule = [{
  type: 'file-library',
  field: 'image',
  title: '校区图片',
  value: '',
  props: {
    limit: 1,
    returnIds: true
  }
}, {
  type: 'input',
  field: 'title',
  title: '名称',
  props: {
    placeholder: '请输入名称'
  },
  validate: [{
    required: true,
    message: '名称不能为空'
  }]
}, {
  type: 'input',
  field: 'contact',
  title: '联系人',
  props: {
    placeholder: '请输入联系人'
  }
}, {
  type: 'input',
  field: 'mobile',
  title: '联系方式',
  props: {
    placeholder: '请输入联系方式'
  }
}, {
  type: 'cascader',
  field: 'region_id',
  title: '所在地区',
  value: [],
  props: {
    options: [],
    filterable: true,
    placeholder: '请选择所在地区',
    props: {
      label: 'title',
      value: 'id',
      children: 'children'
    }
  },
  validate: [{
    required: true,
    message: '所在地区不能为空'
  }]
}, {
  type: 'input',
  field: 'address',
  title: '详细地址',
  props: {
    placeholder: '请在下方定位（可更改）'
  },
  validate: [{
    required: true,
    message: '请在下方定位详细地址'
  }]
}, {
  type: 'input',
  field: 'city',
  hidden: true
}, {
  type: 'input',
  field: 'latitude',
  hidden: true
}, {
  type: 'input',
  field: 'longitude',
  hidden: true
}, {
  type: 'TimePicker',
  field: 'business_hours',
  title: '营业时间',
  // value: ['00:00', '23:59'],
  props: {
    startPlaceholder: '开始营业时间',
    endPlaceholder: '结束营业时间',
    isRange: true,
    valueFormat: 'HH:mm',
    format: 'HH:mm'
  }
}, {
  type: 'select',
  field: 'tag',
  title: '营销标签',
  props: {
    multiple: true,
    allowCreate: true,
    filterable: true,
    placeholder: '输入如：充300送300，周末7.5折等，可输入多个标签'
  }
},
//   {
//     type: 'input',
//     field: 'douyin_pos_id',
//     title: '抖音门店ID',
//     props: {
//       placeholder: '如有开通抖音团购，请填写门店ID'
//     }
//   },
//   {
//     type: 'input',
//     field: 'meituan_pos_id',
//     title: '美团门店ID',
//     props: {
//       placeholder: '如有开通美团团购，请填写门店ID'
//     }
//   },
{
  type: 'radio',
  title: '状态',
  field: 'status',
  value: 1,
  options: [{
    value: 1,
    label: '正常'
  }, {
    value: 0,
    label: '禁用'
  }]
}, {
  type: 'radio',
  title: '是否上线',
  field: 'is_online',
  value: 1,
  options: [{
    value: 1,
    label: '是'
  }, {
    value: 0,
    label: '否'
  }]
}, {
  type: 'inputNumber',
  field: 'sort',
  title: '排序',
  value: 100
}];
exports.formRule = formRule;